import React from "react"
import styled from "styled-components"
import { rem, fluidRange, transparentize } from "polished"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { MdArrowBack } from "react-icons/md"
import rehypeReact from "rehype-react"

import SEO from "../containers/seo"
import _Layout, { Main } from "../containers/layout"
import _HiddenHeading from "../components/hidden-heading"
import TeamMember from "../components/cards/team-member"
import _CaseStudy from "../components/cards/case-study"
import _Category from "../components/category/category"
import { List, ListItem } from "../components/lists/list"
import { Image } from "../components/cards/img-card"

export default function Videos({ data }) {
  return (
    <Layout>
      <SEO
        title={data.video.seoTitle}
        description={data.video.seoDescription.seoDescription}
        creator={data.video.seoCreator}
        image={data.video.seoImage?.localFile?.childImageSharp?.fluid.src}
      />
      <Breadcrumbs>
        <List>
          <ListItem>
            <Link to="/">Home</Link>
          </ListItem>
          <ListItem>
            <Link to="/videos/">Videos</Link>
          </ListItem>
          <ListItem>{data.video.title}</ListItem>
        </List>
      </Breadcrumbs>
      <Header>
        <BackToVideos />
        {data.video.client?.logo &&
          (data.video.client?.website?.length > 0 ? (
            <ClientLogoLink
              href={data.video.client.website}
              target="_blank"
              rel="noreferrer noopener"
              title={data.video.client?.name}
            >
              <ClientLogo
                fluid={data.video.client.logo.localFile.childImageSharp.fluid}
              />
            </ClientLogoLink>
          ) : (
            <ClientLogo
              fluid={data.video.client.logo.localFile.childImageSharp.fluid}
            />
          ))}
        <Heading>{data.video.title}</Heading>
        <Date datetime={data.video.date}>{data.video.formattedDate}</Date>
        <Category
          category={{
            text: data.video.category.text,
            url: `/videos/category/${data.video.category.url}`,
          }}
          tags={data.video.tags.map(({ id, text, url }) => ({
            id,
            text,
            url: `/videos/tag/${url}`,
          }))}
        />
      </Header>
      <Content>
        <strong>{data.video.description.description}</strong>
        {renderAst(data.video.link.childMarkdownRemark.htmlAst)}
      </Content>
      <Footer>
        <HiddenHeading>Footer</HiddenHeading>
        {data.video.author && (
          <Section>
            <SectionTitle>About author</SectionTitle>
            <Author
              photo={
                <Img
                  fluid={
                    data.video.author.photo.localFile.childImageSharp.fluid
                  }
                />
              }
              name={data.video.author.name}
              nameProps={{ as: "h5" }}
              position={data.video.author.position}
              bio={data.video.author.biography.biography}
              socialLinks={data.video.author.socialLinks}
            />
          </Section>
        )}
        {data.videos.nodes.length > 0 && (
          <Section as="aside">
            <SectionTitle>Watch more</SectionTitle>
            <VideoList>
              {data.videos.nodes.map(
                ({ title, thumbnail, slug, category, tags }) => (
                  <Video
                    key={slug}
                    image={
                      <Img fluid={thumbnail.localFile.childImageSharp.fluid} />
                    }
                    title={title}
                    url={`/video/${slug}`}
                    category={{
                      text: category.text,
                      url: `/videos/category/${category.url}`,
                    }}
                    tags={tags.map(({ id, text, url }) => ({
                      id,
                      text,
                      url: `/videos/tag/${url}`,
                    }))}
                  />
                ),
              )}
            </VideoList>
          </Section>
        )}
      </Footer>
    </Layout>
  )
}

const Section = styled.section`
  grid-column: full;
  display: inherit;
  grid-template-columns: inherit;
  column-gap: inherit;
`
const _Content = styled.div`
  grid-column: main;

  @media screen and (min-width: ${rem("1200px")}) {
    grid-column: 4 / span 8;
  }
`

const Content = styled(_Content)`
  font-size: ${rem("18px")};
  font-weight: 300;
  line-height: 1.89;
  word-wrap: break-word;
`
const Breadcrumbs = styled.div`
  grid-column: full;
  justify-self: center;
  width: 100%;
  padding: ${rem("10px")} 0;
  border: 1px solid ${props => transparentize(0.8, props.theme.color.dark)};
  border-left-width: 0;
  border-right-width: 0;
  font-size: ${rem("16px")};
  font-weight: 500;
  color: ${props => props.theme.color.text};

  ${List} {
    display: flex;
    flex-direction: row;
    justify-items: start;
  }

  ${ListItem} {
    width: auto;

    &:not(:last-of-type)::after {
      content: "/";

      padding: 0 ${rem("10px")};

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  & {
    display: inherit;
    grid-template-columns: inherit;
    column-gap: inherit;
  }

  ${List} {
    grid-column: main;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`
const VideoList = styled(_Content).attrs({ as: "div" })`
  display: flex;
  flex-wrap: wrap;
`
const Video = styled(_CaseStudy)`
  margin: ${rem("14px")} 0;

  @media screen and (min-width: ${rem("1200px")}) {
    flex-basis: 48%;
    margin: ${rem("14px")};

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &:hover {
    transform: none;
  }

  ${Image} {
    padding-top: 56.25%;
  }
`
const ClientLogo = styled(_Content).attrs({
  as: Img,
  imgStyle: { objectFit: "contain" },
})`
  max-width: ${rem("200px")};
  user-select: none;
`
const ClientLogoLink = styled(_Content).attrs({ as: "a" })``
const Category = styled(_Content).attrs({ as: _Category })``
const HiddenHeading = props => <_HiddenHeading as="h3" {...props} />
const Header = styled(Section).attrs({ as: "header" })`
  row-gap: ${rem("36px")};
`
const Footer = styled(Section).attrs({ as: "footer" })`
  row-gap: ${rem("85px")};
`
const Heading = styled(_Content).attrs({ as: "h2" })`
  line-height: 1.3;
  font-weight: 500;
  margin: 0;

  ${fluidRange(
    {
      prop: "fontSize",
      fromSize: "24px",
      toSize: "42px",
    },
    "400px",
    "1000px",
  )}
`
const Date = styled(_Content).attrs({ as: "time" })`
  font-size: ${rem("16px")};
  font-weight: 500;
  color: ${props => transparentize(0.7, props.theme.color.text)};
`
const SectionTitle = styled(_Content).attrs(props => ({ as: "h4", ...props }))`
  font-size: ${rem("24px")};
  font-weight: 500;
  letter-spacing: ${rem("-0.39px")};
`
const Author = styled(_Content).attrs({ as: TeamMember })``
const Layout = styled(_Layout)`
  ${Main} {
    padding-top: ${rem("30px")};

    ${fluidRange(
      {
        prop: "paddingBottom",
        fromSize: "65px",
        toSize: "115px",
      },
      "400px",
      "1200px",
    )}
  }
`
const BackToVideos = styled(SectionTitle).attrs({
  as: Link,
  to: "/videos",
  children: (
    <>
      <MdArrowBack /> <span>Videos</span>
    </>
  ),
})`
  grid-column: full;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;

  > svg {
    color: ${props => props.theme.color.iconPrimary};

    & + span {
      margin-left: ${rem("5px")};
    }
  }

  @media screen and (min-width: ${rem("1200px")}) {
    display: inherit;
    grid-template-columns: inherit;
    column-gap: inherit;
    grid-column: full;

    > svg {
      grid-column: 3;
      justify-self: end;

      & + span {
        margin-left: 0;
      }
    }

    > span {
      grid-column: 4 / span 8;
      justify-self: start;
    }
  }
`

const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler

export const query = graphql`
  query($slug: String) {
    video: contentfulVideo(slug: { eq: $slug }) {
      title
      date: createdAt
      formattedDate: createdAt(formatString: "MMM Do, YYYY")
      seoTitle
      seoDescription {
        seoDescription
      }
      seoCreator
      seoImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2048) {
              src
            }
          }
        }
      }
      category {
        text
        url
      }
      tags {
        id
        text
        url
      }
      description {
        description
      }
      client {
        name
        website
        logo {
          localFile {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
      link {
        childMarkdownRemark {
          htmlAst
        }
      }
      author {
        name
        position
        biography {
          biography
        }
        socialLinks: childrenContentfulAuthorSocialLinkJsonNode {
          id
          type
          url
        }
        photo {
          localFile {
            childImageSharp {
              fluid(maxHeight: 136, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
    videos: allContentfulVideo(
      limit: 2
      filter: { slug: { ne: $slug } }
      sort: { fields: [createdAt, featured], order: [DESC, DESC] }
    ) {
      nodes {
        title
        category {
          text
          url
        }
        tags {
          id
          text
          url
        }
        slug
        thumbnail {
          localFile {
            childImageSharp {
              fluid(maxWidth: 790, quality: 85) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`
